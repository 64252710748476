import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Link from 'utils/localized-link';

import Button from '@youship/components/objects/button';
import Img from 'components/image';

import './styles.scss';

const Featured = ({ classNames, description, image, invert, title, linkProps }) => {
  const imageColumnsClassName = invert ? 'col col-12 col-md-5 offset-lg-1 d-flex order-md-2' : 'col col-12 col-md-5 d-md-flex';
  const textColumnsClassName = invert ? 'col col-12 col-md-6 col-lg-5 offset-md-1 order-md-1' : 'col col-12 col-md-5 col-md-6 col-lg-5 offset-lg-1';

  return (
    <div className={`featured${classNames ? ` ${classNames}` : ''}`}>
      <div className="row">
        <div className={imageColumnsClassName}>
          {image && (
            <div className="featured__image-wrapper">
              <Img
                alt={title || 'Featured'}
                classNames="featured__image"
                src={image}
              />
            </div>
          )}
        </div>
        <div className={`${textColumnsClassName} d-flex align-items-center`}>
          {(title || description || (linkProps.to && linkProps.text)) && (
            <div className="featured__text">
              {title && (
                <div className="h1 featured__title">
                  <FormattedMessage id={title} />
                </div>
              )}
              {description && (
                <div className="featured__description">
                  <FormattedMessage id={description} />
                </div>
              )}
              {linkProps && linkProps.to && linkProps.text && (
                <Button
                  context="primary"
                  linkComponent={Link}
                  linkProps={{ to: linkProps.to }}
                  noBackground
                  noPadding
                  noShadow
                  small
                >
                  <FormattedMessage id={linkProps.text} />
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


Featured.propTypes = {
  classNames: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  invert: PropTypes.bool,
  linkProps: PropTypes.shape({
    to: PropTypes.string,
    text: PropTypes.string
  }),
  title: PropTypes.string
};

Featured.defaultProps = {
  classNames: null,
  description: null,
  image: null,
  invert: null,
  linkProps: null,
  title: null
};

export default Featured;
